import React from 'react';
import { FormGroup, FormSelect } from 'shards-react';
import { FISCAL_CONDITIONS } from '../../data/fields/mappings/fiscalConditionsMap';

export const FiscalConditionSelect = (props) => {
  return (
    <FormGroup>
      <label htmlFor="fiscal-condition">Condición Fiscal</label>
      <FormSelect id="fiscal-condition" {...props}>
        <option value="">-- Condicion Fiscal --</option>
        {FISCAL_CONDITIONS.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </FormSelect>
    </FormGroup>
  );
};
