import React from 'react';
import { FormGroup, FormSelect } from 'shards-react';
import { PROVINCES } from '../../data/fields/mappings/provincesMap';

export const ProvinceSelect = (props) => {
  return (
    <FormGroup>
      <label htmlFor="province">Provincia</label>
      <FormSelect id="province" {...props}>
        <option value="">-- Seleccione una provincia --</option>
        {PROVINCES.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </FormSelect>
    </FormGroup>
  );
};
