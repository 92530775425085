import gql from 'graphql-tag';

const CompanyFragments = {};

CompanyFragments.fields = gql`
  fragment CompanyFields on Company {
    __typename
    id
    legalName
    taxId
    metadata
    address
    phoneNumber
    paymentMethod
  }
`;

export default CompanyFragments;
