import React from 'react';
import { Button, Form, Row } from 'shards-react';
import styled from 'styled-components';
import SearchControl from './SearchControl';
import { AuthContext } from '../../contexts/AuthContext';
import { Can } from '../../permissions/helpers';

const CustomFormGroup = styled.div`
  display: flex;
`;

class SearchBar extends React.Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = { values: {}, showClear: false };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.fields === this.props.fields) return;
    this.setState({ values: {} });
  }

  handleChange = (value, key) => {
    this.setState((state) => {
      return {
        values: {
          ...state.values,
          [key]: value !== '' ? value : undefined
        }
      };
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ showClear: true });
    this.props.onSubmit(this.state.values);
  };

  handleFilterClear = (e) => {
    e.preventDefault();
    this.setState({ showClear: false, values: {} });
    this.props.onSubmit({});
  };

  isCheckbox = (type) => {
    return type === 'checkbox' || type === 'indeterminate-checkbox';
  };

  readInputvalue = (input, type) => {
    let val = this.isCheckbox(type) ? input.checked : input.value;

    if (input.indeterminate) {
      val = input.value;
    }

    return val;
  };

  renderSearchControl = (key, label, type, content, permission) => {
    return (
      <Can action={permission} allowIfEmpty key={key}>
        <CustomFormGroup key={key}>
          <SearchControl
            name={`${key}-Search`}
            type={type}
            key={key}
            label={label}
            value={this.state.values[key]}
            content={content}
            onChange={(e) => {
              const val = this.readInputvalue(e.target, type);
              this.handleChange(val, key);
            }}
          />
        </CustomFormGroup>
      </Can>
    );
  };

  renderNestedSearchController = ({ key, label, type, content, permission }) => {
    if (type === 'nested') {
      return (
        <Can action={permission} allowIfEmpty key={key}>
          <CustomFormGroup key={key}>
            <label htmlFor={key}>{label}</label>
            <br />
            {content.map(({ key, label, type }) => this.renderSearchControl(key, label, type))}
          </CustomFormGroup>
        </Can>
      );
    }
    return this.renderSearchControl(key, label, type, content, permission);
  };

  render() {
    const { fields } = this.props;

    return (
      <Form onSubmit={this.handleSubmit}>
        <Row form style={{ gap: '1rem' }}>
          {fields.map(({ roles, ...rest }) => this.renderNestedSearchController(rest))}
          <Button type="submit" size="md">
            <i className="material-icons">search</i>
          </Button>
          {this.state.showClear && (
            <Button
              onClick={this.handleFilterClear}
              size="md"
              theme="outline-secondary"
              title="Limpiar filtros"
              type="button"
            >
              <i className="material-icons">clear</i>
            </Button>
          )}
        </Row>
      </Form>
    );
  }
}

export default SearchBar;
