export const SEGMENTS = [
  { value: 'MEGRA', label: 'MEGRA' },
  { value: 'CORPORATIVAS', label: 'Corporativas' },
  { value: 'PEQUENAS_EMPRESAS', label: 'Pequeñas Empresas' },
  { value: 'NYP', label: 'NyP' },
  { value: 'CONSORCIOS', label: 'Consorcios' },
  { value: 'ESTADO_PROVINCIAL', label: 'Estado Provincial' },
  { value: 'ESTADO_MUNICIPAL', label: 'Estado Municipal' },
  { value: 'ESTADO_NACIONAL', label: 'Estado Nacional' },
  { value: 'OTROS', label: 'Otros' }
];
