import React from 'react';

export default function OwnerUserNameField({ value }) {
  if (!value) {
    return <td></td>;
  }
  const owner = value.find((u) => u.owner);

  if (owner) {
    return (
      <td>
        {owner.firstName} {owner.lastName}
      </td>
    );
  }
}
