import ListTextField from '../../components/fields/list/TextField';
import ListSubscriptionStateField from '../../components/fields/list/SubscriptionStateField';
import ListChangeField from '../../components/fields/list/ChangeField';
import ListDateField from '../../components/fields/list/DateField';
import SubscriptionEventField from '../../components/fields/show/SubscriptionEventField';
import ShowSubscriptionStateField from '../../components/fields/show/SubscriptionStateField';
import ShowTextField from '../../components/fields/show/TextField';
import ShowDateField from '../../components/fields/show/DateField';
import ShowLinkToModelField from '../../components/fields/show/LinkToModelField';
import ShowAttributeField from '../../components/fields/show/AttributeField';
import AttributeField from '../../components/fields/list/AttributeField';
import TruncatedEmail from '../../components/fields/list/TruncatedEmail';
import TableBooleanField from '../../components/fields/list/TableBooleanField';
import SubscriptionCancelMotiveField from '../../components/fields/show/SubscriptionCancelMotiveField';
import BooleanField from '../../components/fields/show/BooleanField';

const subscriptionFields = {
  list: {
    state: { label: 'Estado', content: ListSubscriptionStateField },
    product: { label: 'Producto', content: AttributeField('name') },
    terminalsCount: { label: 'Terminales', content: ListTextField },
    account: { label: 'Cuenta', content: AttributeField('name') },
    partner: {
      label: 'Partner',
      content: AttributeField('name'),
      permission: 'sub:list:show-partner'
    },
    salesOwner: {
      label: 'Implementador',
      content: TruncatedEmail('email'),
      permission: 'sub:list:show-sales-owner'
    },
    createdAt: { label: 'Fecha de creación', content: ListDateField }
  },
  show: {
    id: { label: 'ID', content: ShowTextField },
    state: { label: 'Estado', content: ShowSubscriptionStateField },
    product: {
      label: 'Producto',
      content: ShowAttributeField('name')
    },
    country: { label: 'Pais', content: ShowTextField },
    integrationByApi: { label: 'Integrado por API', content: BooleanField },
    account: {
      label: 'Cuenta',
      type: 'object',
      content: {
        id: { label: 'ID', content: ShowLinkToModelField('/accounts') },
        name: { label: 'Nombre', content: ShowTextField }
      }
    },
    partner: {
      label: 'Partner',
      content: ShowAttributeField('name')
    },
    contactCenter: { label: 'Contact center', content: ShowAttributeField('name') },
    fixedPrice: { label: 'Precio Fijo', content: BooleanField },
    salesOwner: {
      label: 'Implementador',
      type: 'object',
      content: {
        email: { label: 'Email', content: ShowTextField }
      }
    },
    netAmount: { label: 'Importe neto', content: ShowTextField, permission: 'sub:show:amounts' },
    netAmountAfterDiscounts: {
      label: 'Importe neto c/desc',
      content: ShowTextField,
      permission: 'sub:show:amounts'
    },
    chargeableAmount: {
      label: 'Importe Cobrado',
      content: ShowTextField,
      permission: 'sub:show:amounts'
    },
    initialBilling: { label: 'Fecha de primera factura', content: ShowDateField },
    createdAt: { label: 'Fecha de creación', content: ShowDateField },
    terminalsCount: { label: 'Cantidad de terminales', content: ShowTextField },
    freeUntil: { label: 'Finaliza periodo de prueba el: ', content: ShowDateField },
    events: {
      label: 'Eventos',
      type: 'nested',
      link: false,
      content: {
        name: { label: 'Evento', content: SubscriptionEventField },
        eventTime: { label: 'Fecha', content: ListDateField },
        metadata: { label: 'Motivo', content: SubscriptionCancelMotiveField }
      }
    },
    huntingEvents: {
      label: 'Eventos de hunting',
      type: 'nested',
      link: false,
      content: {
        name: { label: 'Evento', content: SubscriptionEventField },
        eventTime: { label: 'Fecha', content: ListDateField }
      },
      permission: 'sub:show:hunting-events'
    },
    company: {
      label: 'Compañía',
      type: 'object',
      content: {
        id: { label: 'ID', content: ShowTextField },
        legalName: { label: 'Nombre', content: ShowTextField },
        taxId: { label: 'Tax ID', content: ShowTextField },
        phoneNumber: { label: 'Teléfono', content: ShowTextField },
        address: {
          label: 'Dirección',
          type: 'object',
          content: {
            country: { label: 'País', content: ShowTextField },
            postal_code: { label: 'Código postal', content: ShowTextField },
            province: { label: 'Provincia', content: ShowTextField },
            street: { label: 'Calle', content: ShowTextField }
          }
        }
      }
    },
    discounts: {
      label: 'Descuentos activos',
      type: 'nested',
      links: false,
      content: {
        name: { label: 'Nombre', content: ShowTextField },
        percentage: { label: 'Porcentual?', content: TableBooleanField },
        amount: { label: 'Valor', content: ShowTextField },
        recurringCycles: { label: 'Ciclos', content: ShowTextField },
        recurringCyclesLeft: { label: 'Ciclos restantes', content: ShowTextField },
        createdAt: { label: 'Fecha creación', content: ListDateField }
      }
    },
    discountsHistory: {
      label: 'Historico de Descuentos',
      type: 'nested',
      links: false,
      content: {
        name: { label: 'Nombre', content: ShowTextField },
        percentage: { label: 'Porcentual?', content: TableBooleanField },
        amount: { label: 'Valor', content: ShowTextField },
        recurringCycles: { label: 'Ciclos', content: ShowTextField },
        recurringCyclesLeft: { label: 'Ciclos restantes', content: ShowTextField },
        createdAt: { label: 'Fecha creación', content: ListDateField }
      }
    },
    audit: {
      label: 'Auditabilidad',
      type: 'nested',
      links: false,
      content: {
        user: { label: 'Usuario', content: AttributeField('email') },
        before: { label: 'Antes', content: ListChangeField },
        after: { label: 'Despues', content: ListChangeField },
        date: { label: 'Fecha', content: ListDateField }
      }
    },
    tasks: {
      label: 'Tareas',
      type: 'nested',
      links: false,
      content: {
        priority: { label: 'Prioridad', content: AttributeField('name') },
        state: { label: 'Estado', content: AttributeField('name') },
        creator: { label: 'Creador', content: AttributeField('email') },
        assignedTo: { label: 'Asignado', content: AttributeField('email') },
        dueAt: { label: 'Vencimiento', content: ListDateField }
      }
    },
    comments: {
      label: 'Comentarios',
      type: 'nested',
      content: {
        writerEmail: { label: 'Escritor', content: ShowTextField },
        body: { label: 'Comentario', content: ShowTextField },
        productName: { label: 'Producto', content: ShowTextField },
        createdAt: { label: 'Fecha', content: ShowDateField }
      }
    }
  },
  search: [
    {
      key: 'partnerId',
      label: 'Filtrar por partner',
      type: 'partner',
      permission: 'sub:search:partner'
    },
    {
      key: 'contactCenterId',
      label: 'Filtrar por contact center',
      type: 'partner',
      permission: 'sub:search:contact-center'
    },
    {
      key: 'salesOwnerId',
      label: 'Implementador',
      type: 'users',
      permission: 'sub:search:sales-owner'
    },
    { key: 'accountName', label: 'Cuenta' },
    { key: 'userEmail', label: 'Email' },
    {
      key: 'state',
      label: 'Filtrar por estados',
      type: 'select',
      content: [
        { label: 'Registrado', value: 'awaiting_setup' },
        { label: 'Probando', value: 'free_trial' },
        { label: 'Esperando Info de Pago', value: 'awaiting_payment_data' },
        { label: 'Dentro del sandbox', value: 'sandbox' },
        { label: 'Usando la plataforma', value: 'usable' },
        { label: 'Cancelada', value: 'canceled' }
      ]
    },
    { key: 'country', label: 'Filtrar por país', type: 'country' },
    { key: 'productName', label: 'Filtrar por producto', type: 'product' },
    { key: 'taxId', label: 'CUIT' },
    {
      key: 'events',
      label: 'Filtrar por eventos',
      type: 'nested',
      content: [
        {
          key: 'salesDataUpdated',
          label: 'Datos de contacto?',
          type: 'indeterminate-checkbox'
        },
        {
          key: 'onboardingFinished',
          label: 'Producto OK?',
          type: 'indeterminate-checkbox'
        },
        {
          key: 'subscriptionValidated',
          label: 'Carta OK?',
          type: 'indeterminate-checkbox'
        },
        {
          key: 'billingDataUpdated',
          label: 'Actualizó información de pago?',
          type: 'indeterminate-checkbox'
        }
      ]
    }
  ]
};

export default subscriptionFields;
