import React from 'react';
import gql from 'graphql-tag';

import SmartQuery from './SmartQuery.js';

export const query = gql`
  query Accounts($filter: AccountsFilter, $page: Int) {
    accounts(filter: $filter, page: $page) {
      id
      name
      taxId
      createdAt
      enterprise
      users {
        id
        email
        firstName
        lastName
        owner
      }
    }
  }
`;

export default function QueryAccounts({ filter, page, children }) {
  return (
    <SmartQuery query={query} variables={{ filter: filter, page: page }}>
      {(data) => children(data.accounts)}
    </SmartQuery>
  );
}
