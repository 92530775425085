import React from 'react';
import {
  Form,
  FormGroup,
  Button,
  FormSelect,
  DatePicker,
  FormTextarea,
  FormCheckbox
} from 'shards-react';
import QueryTaskPriorities from '../../queries/QueryTaskPriorities';
import QueryAppUsers from '../../queries/QueryAppUsers';
import QueryTaskTypes from '../../queries/QueryTaskTypes';
import QueryTaskResolutionTypes from '../../queries/QueryTaskResolutionTypes';
import { AuthContext } from '../../contexts/AuthContext.js';
import { Can } from '../../permissions/helpers';

export default class TaskForm extends React.Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    const {
      id,
      priority,
      assignedTo,
      dueAt,
      description,
      resolutionType,
      type,
      taskableType,
      taskableId
    } = props.task || {};

    this.state = {
      id,
      taskableType: taskableType || props.taskableType,
      taskableId: taskableId || props.taskableId,
      description,
      dueAt: dueAt && new Date(dueAt),
      typeId: type && type.id,
      resolutionTypeId: resolutionType && resolutionType.id,
      assignedToId: assignedTo && assignedTo.id,
      priorityId: (priority && priority.id) || '',
      closed: resolutionType && resolutionType.id,
      editing: id && true,
      assignModel: true,
      submitting: false
    };
  }

  modelName = () => {
    switch (this.state.taskableType) {
      case 'Invitation':
        return 'invitación';
      case 'Subscription':
        return 'suscripción';
      default:
        break;
    }
  };

  render() {
    const {
      id,
      priorityId,
      assignedToId,
      dueAt,
      taskableType,
      taskableId,
      description,
      typeId,
      resolutionTypeId,
      assignModel
    } = this.state;

    return (
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          this.setState({ submitting: true });
          this.props
            .mutate({
              variables: {
                priorityId,
                assignedToId,
                dueAt,
                id,
                taskableType,
                taskableId,
                description,
                typeId,
                resolutionTypeId,
                options: { assignModel }
              }
            })
            .then((response) => this.props.onSubmit(response.data.task))
            .catch((error) => this.props.onError(error))
            .finally(() => this.setState({ submitting: false }));
        }}
      >
        <QueryTaskTypes>
          {(types) => (
            <FormGroup>
              <label htmlFor="typeId">Tipo*</label>
              <FormSelect
                disabled={this.state.editing}
                defaultValue={this.state.typeId}
                id="typeId"
                onChange={(event) => {
                  this.setState({ typeId: event.target.value });
                }}
              >
                <option value="">-- Seleccionar tipo de tarea ---</option>
                {types.map((p) => (
                  <option key={p.id} value={p.id}>
                    {p.name}
                  </option>
                ))}
              </FormSelect>
            </FormGroup>
          )}
        </QueryTaskTypes>

        <QueryTaskPriorities>
          {(priorities) => (
            <FormGroup>
              <label htmlFor="priorityId">Prioridad*</label>
              <FormSelect
                disabled={this.state.closed}
                value={this.state.priorityId}
                id="priorityId"
                onChange={(event) => {
                  this.setState({ priorityId: event.target.value });
                }}
              >
                <option value="">-- Seleccionar prioridad ---</option>
                {priorities.map((p) => (
                  <option key={p.id} value={p.id}>
                    {p.name}
                  </option>
                ))}
              </FormSelect>
            </FormGroup>
          )}
        </QueryTaskPriorities>

        <Can action="tsk:edit:show-assigned">
          <QueryAppUsers>
            {(users) => (
              <FormGroup>
                <label htmlFor="assignedToId">Asignado a</label>
                <FormSelect
                  disabled={this.state.closed}
                  id="assignedToId"
                  defaultValue={this.state.assignedToId}
                  onChange={(event) => {
                    this.setState({ assignedToId: event.target.value });
                  }}
                >
                  <option value="">-- Seleccionar usuario ---</option>
                  {users.map((u) => (
                    <option key={u.id} value={u.id}>
                      {u.email}
                    </option>
                  ))}
                </FormSelect>
              </FormGroup>
            )}
          </QueryAppUsers>
        </Can>

        <Can action="tsk:edit:assign" data={{ taskableType, assignedToId }}>
          <FormGroup>
            <FormCheckbox
              checked={assignModel}
              onChange={(event) => {
                this.setState({ assignModel: event.target.checked });
              }}
            >
              asignar {this.modelName()} al implementador
            </FormCheckbox>
          </FormGroup>
        </Can>

        <FormGroup>
          <label htmlFor="dueAt">Vencimiento</label>
          <br />
          <DatePicker
            isClearable={!this.state.closed}
            disabled={this.state.closed}
            dateFormat="yyyy/MM/dd HH:mm"
            timeFormat="HH:mm"
            showTimeSelect
            selected={this.state.dueAt}
            dropdownMode="select"
            onChange={(date) => this.setState({ dueAt: date })}
          />
        </FormGroup>

        {this.state.editing && (
          <QueryTaskResolutionTypes>
            {(types) => (
              <FormGroup>
                <label htmlFor="resolutionTypeId">Tipo de resolución</label>
                <FormSelect
                  disabled={this.state.closed}
                  defaultValue={this.state.resolutionTypeId}
                  id="resolutionTypeId"
                  onChange={(event) => {
                    this.setState({ resolutionTypeId: event.target.value });
                  }}
                >
                  <option value="">-- Seleccionar tipo de resolución ---</option>
                  {types.map((p) => (
                    <option key={p.id} value={p.id}>
                      {p.name}
                    </option>
                  ))}
                </FormSelect>
              </FormGroup>
            )}
          </QueryTaskResolutionTypes>
        )}

        <FormGroup>
          <label htmlFor="description">Descripción</label>

          <FormTextarea
            value={this.state.description || ''}
            rows={5}
            onChange={(event) => {
              this.setState({ description: event.target.value });
            }}
          />
        </FormGroup>

        <Button disabled={this.state.submitting} type="submit">
          {this.state.id ? 'Actualizar' : 'Crear'} Tarea
        </Button>
      </Form>
    );
  }
}
