import React from 'react';
import { FormGroup, FormSelect } from 'shards-react';
import { BUSINESS_CATEGORIES } from '../../data/fields/mappings/businessCategoryMap';

export const BusinessCategorySelect = (props) => {
  return (
    <FormGroup>
      <label htmlFor="business-category">Rubro</label>
      <FormSelect id="business-category" {...props}>
        <option value="">-- Seleccione un rubro --</option>
        {BUSINESS_CATEGORIES.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </FormSelect>
    </FormGroup>
  );
};
