import ShowTextField from '../../components/fields/show/TextField';
import ListDateField from '../../components/fields/list/DateField';
import ShowDateField from '../../components/fields/show/DateField';
import InvitationAccepted from '../../components/fields/list/InvitationAccepted';
import AttributeField from '../../components/fields/list/AttributeField';
import BooleanField from '../../components/fields/show/BooleanField';
import SendInvite from '../actions/SendInvite';

const invitationFields = {
  list: {
    inviter: { label: 'Creador', content: AttributeField('email') },
    partner: { label: 'Partner', content: AttributeField('name') },
    state: { label: 'Estado', content: AttributeField('label') },
    inviteeEmail: { label: 'Email', content: ShowTextField },
    emailSentAt: { label: 'Email enviado', content: ListDateField },
    accepted: { label: 'Aceptada', content: InvitationAccepted, virtual: true }
  },
  customLinks: [{ label: 'Enviar email', content: SendInvite }],
  show: {
    id: { label: 'ID', content: ShowTextField },
    inviter: {
      label: 'Creado por',
      type: 'object',
      content: {
        firstName: { label: 'Nombre', content: ShowTextField },
        lastName: { label: 'Apellido', content: ShowTextField },
        email: { label: 'Email', content: ShowTextField }
      }
    },
    partner: {
      label: 'Partner',
      type: 'object',
      content: {
        id: { label: 'ID', content: ShowTextField },
        name: { label: 'Nombre', content: ShowTextField }
      }
    },
    inviteeEmail: { label: 'Invitado', content: ShowTextField },
    acceptedAt: { label: 'Fecha de Aceptación', content: ShowDateField },
    acceptedByOther: { label: 'Aceptada Por otro partner?', content: BooleanField },
    tasks: {
      label: 'Tareas',
      type: 'nested',
      links: false,
      content: {
        priority: { label: 'Prioridad', content: AttributeField('name') },
        state: { label: 'Estado', content: AttributeField('name') },
        creator: { label: 'Creador', content: AttributeField('email') },
        assignedTo: { label: 'Asignado', content: AttributeField('email') },
        dueAt: { label: 'Vencimiento', content: ListDateField }
      }
    }
  },
  search: [
    {
      key: 'partnerId',
      label: 'Partner',
      type: 'partner',
      permission: 'inv:search:partner'
    },
    { key: 'stateId', label: 'Estado', type: 'invitationStates' },
    {
      key: 'salesOwnerId',
      label: 'Implementador',
      type: 'users',
      permission: 'inv:search:sales-owner'
    },
    { key: 'inviterEmail', label: 'Creador' },
    { key: 'inviteeEmail', label: 'Invitado' }
  ]
};

export default invitationFields;
