export const mapGraphQLErrors = (error) => {
  const errorDetails =
    error.graphQLErrors &&
    error.graphQLErrors[0] &&
    error.graphQLErrors[0].extensions &&
    error.graphQLErrors[0].extensions.response &&
    error.graphQLErrors[0].extensions.response.body &&
    error.graphQLErrors[0].extensions.response.body.error &&
    error.graphQLErrors[0].extensions.response.body.error.errors[0];

  let errorMessage = '';

  if (errorDetails) {
    if (errorDetails.metadata && errorDetails.metadata.details) {
      const details = errorDetails.metadata.details;
      errorMessage = Object.entries(details)
        .map(([key, value]) => `${key} -- ${value[0].error}`)
        .join(', ');
    } else {
      errorMessage = errorDetails.message;
    }
  }

  if (
    error.graphQLErrors &&
    error.graphQLErrors[0] &&
    error.graphQLErrors[0].extensions &&
    error.graphQLErrors[0].extensions.code === 'PARTNER_ADMIN/VALIDATION_ERROR' &&
    error.graphQLErrors[0].message === 'El usuario ya existe.'
  ) {
    return {
      fieldErrors: { email: true },
      generalError: 'Ya existe una cuenta registrada con este correo electronico'
    };
  }

  const errorMapping = [
    {
      message: 'tax_id -- taken',
      standardError: 'El CUIT ya ha sido utilizado',
      field: 'cuit'
    },
    {
      message: 'cbu -- length',
      standardError: 'Error en la cantidad de caracteres del CBU',
      field: 'cbu'
    },
    {
      message: 'cbu -- bank_invalid',
      standardError: 'El banco indicado por el CBU es inválido',
      field: 'cbu'
    },
    {
      message: 'cbu -- not_numeric',
      standardError: 'El CBU indicado no respeta el formato numérico',
      field: 'cbu'
    },
    {
      message: 'Validation failed: Email has already been taken',
      standardError: 'Ya existe una cuenta registrada con este correo electronico',
      field: 'email'
    },
    {
      message: 'Ocurrió un error al guardar el usuario. ValidationError: last_name:',
      standardError: 'Error de formato del campo Apellido',
      field: 'last_name'
    },
    {
      message: 'Ocurrió un error al guardar el usuario. ValidationError: first_name:',
      standardError: 'Error de formato del campo Nombre',
      field: 'first_name'
    },
    {
      message: 'No se pudo enviar el correo de cambio de contraseña',
      standardError: 'No se pudo enviar el correo de cambio de contraseña'
    },
    {
      message: 'Error al crear el reseteo de contraseña',
      standardError: 'Error al crear el reseteo de contraseña'
    },
    {
      message: 'Producto no válido. Las opciones disponibles son',
      standardError: 'El producto seleccionado no está disponible',
      field: 'product'
    },
    {
      message: 'Ya existe una cuenta registrada con este correo electronico',
      standardError: 'Ya existe una cuenta registrada con este correo electronico',
      field: 'email'
    }
  ];

  const message = errorMessage || 'Ha ocurrido un error inesperado.';
  const matchedError = errorMapping.find((err) => message.includes(err.message));
  if (matchedError) {
    return {
      fieldErrors: { [matchedError.field]: true },
      generalError: matchedError.standardError
    };
  }

  return {
    fieldErrors: {},
    generalError: 'Ha ocurrido un error inesperado.'
  };
};
