import React from 'react';
import { Row, Col } from 'shards-react';
import { get, isEmpty } from 'lodash';

import Table from './Table';

const renderField = (type, content, fieldValue, path, links, editable) => {
  if (type === 'nested') {
    if (!isEmpty(fieldValue)) {
      // we need to render a list of (sub-)elements
      return (
        <Table editable={editable} data={fieldValue} fields={content} links={links} path={path} />
      );
    }
    return <span>No hay datos</span>;
  }
  if (type === 'object') {
    // we are rendering an object
    return ShowBody({ data: fieldValue, fields: content });
  }
  const FieldComponent = content;
  return <FieldComponent value={fieldValue} />; // a raw value like a string or number
};

const ShowBody = ({ data, fields }) => {
  if (!fields) return null;
  return Object.entries(fields).map(
    ([fname, { fieldName, label, content, type, path, links, virtual, editable }]) => {
      const field = fname || fieldName;
      if (type === 'title') {
        return (
          <Row style={{ marginBottom: '1rem' }}>
            <h5>{label}</h5>
          </Row>
        );
      }
      return data ? (
        <React.Fragment key={data.id}>
          <Row>
            <Col lg="3" sm="6" xs="12">
              {label}
            </Col>
            <Col lg="9" sm="6" xs="12">
              {renderField(type, content, virtual ? data : get(data, field), path, links, editable)}
            </Col>
          </Row>
          <hr />
        </React.Fragment>
      ) : null;
    }
  );
};

export default ShowBody;
