/**
 * Utility function that calls the provided parameter if it's a function,
 * otherwise returns the parameter as is.
 *
 * @param {Function|any} functionOrValue - Function to be called or value to be returned
 * @param {...any} args - Arguments to pass to the function if it is a function
 * @returns {any} - Result of the function call or the original value
 */
const callIfFunction = (functionOrValue, ...args) => {
  return typeof functionOrValue === 'function' ? functionOrValue(...args) : functionOrValue;
};

export default callIfFunction;
