import React from 'react';
import { FISCAL_CONDITIONS } from '../../../data/fields/mappings/fiscalConditionsMap';
import { PROVINCES } from '../../../data/fields/mappings/provincesMap';
import { SEGMENTS } from '../../../data/fields/mappings/segmentsMap';
import { BUSINESS_CATEGORIES } from '../../../data/fields/mappings/businessCategoryMap';

const maps = {
  FISCAL_CONDITIONS,
  PROVINCES,
  SEGMENTS,
  BUSINESS_CATEGORIES
};

export default function MappedField(mapType) {
  const map = maps[mapType];
  return function({ value }) {
    return <td>{value && map.find((i) => i.value === value).label}</td>;
  };
}
