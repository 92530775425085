import React from 'react';
import { BrowserRouter, Switch, Route as BrowserRoute, Redirect } from 'react-router-dom';
// Apollo
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';
import { get } from 'lodash';
import './App.css';
import List from './components/List.js';
import SubscriptionEdit from './components/edit/SubscriptionEdit.js';
import Show from './components/Show.js';
import DefaultLayout from './layouts/DefaultLayout.js';
import taskFields from './data/fields/taskFields';
import accountFields from './data/fields/accountFields';
import subscriptionFields from './data/fields/subscriptionFields';
import QueryTasks from './queries/QueryTasks';
import QueryTask from './queries/QueryTask';
import QueryAccounts from './queries/QueryAccounts';
import QueryAccount from './queries/QueryAccount';
import QueryUser from './queries/QueryUser';
import QuerySubscriptions from './queries/QuerySubscriptions';
import QuerySubscription from './queries/QuerySubscription';
import QueryAccountMigrations from './queries/QueryAccountMigrations';
import QueryAccountMigration from './queries/QueryAccountMigration';
import InviteUser from './components/edit/InviteUser';
import { useAuth } from './contexts/AuthContext';
import NewTask from './components/edit/NewTask';
import TaskEdit from './components/edit/EditTask';
import invitationFields from './data/fields/invitationFields';
import userFields from './data/fields/userFields';
import accountMigrationFields from './data/fields/accountMigrationFields';
import QueryInvitations from './queries/QueryInvitations';
import QueryInvitation from './queries/QueryInvitation';
import AccountEdit from './components/edit/AccountEdit';
import UserEdit from './components/edit/UserEdit';
import SearchResults from './components/GlobalSearch/SearchResults';
import { useAuthorize } from './permissions/helpers';
import AccountMigrationCreate from './components/create/AccountMigrationCreate';
import CreateUser from './components/edit/CreateUser.js';

function Route({ component: Component, layout: Layout, path, exact, redirect, ...rest }) {
  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <BrowserRoute
      exact={exact}
      path={path}
      render={(props) => {
        return (
          <Layout {...props}>
            <Component {...{ ...rest, ...props }} />
          </Layout>
        );
      }}
    />
  );
}

export default function Router() {
  const { authenticated, user } = useAuth();
  const isAuthorized = useAuthorize();

  if (authenticated === null) return null;
  if (authenticated === false) {
    window.location.href = `${process.env.REACT_APP_LOGIN_URL}?redirect=${window.location}`;
    return null;
  }

  const client = new ApolloClient({
    uri: process.env.REACT_APP_BACKEND_APOLLO_URL,
    credentials: 'include',
    cache: new InMemoryCache()
  });

  return (
    <BrowserRouter>
      <ApolloProvider client={client}>
        <Switch>
          <Route exact path="/" redirect="/invitations" />
          <Route
            component={SearchResults}
            exact
            layout={DefaultLayout}
            path="/search"
            title="Resultado de la búsqueda"
          />
          <Route
            component={List}
            exact
            editable
            fields={taskFields.list}
            layout={DefaultLayout}
            path="/tasks"
            queryData={QueryTasks}
            searchKeys={taskFields.search}
            title="Tareas"
          />
          <Route
            component={List}
            exact
            fields={invitationFields.list}
            layout={DefaultLayout}
            path="/invitations"
            queryData={QueryInvitations}
            searchKeys={invitationFields.search}
            customLinks={invitationFields.customLinks}
            title="Invitaciones"
          />
          <Route
            exact
            enableTask="Invitation"
            component={Show}
            fields={invitationFields.show}
            layout={DefaultLayout}
            path="/invitations/:id"
            queryData={QueryInvitation}
            title="Ver invitación"
          />
          <Route
            component={NewTask}
            exact
            layout={DefaultLayout}
            path="/tasks/:taskableType/:taskableId/new"
            title="Crear tarea"
          />
          <Route
            exact
            editable
            component={Show}
            fields={taskFields.show}
            layout={DefaultLayout}
            path="/tasks/:id"
            queryData={QueryTask}
            title="Ver tarea"
          />
          <Route
            component={TaskEdit}
            layout={DefaultLayout}
            path="/tasks/:id/edit"
            queryData={QueryTask}
            title="Modificar tarea"
          />
          <Route
            component={InviteUser}
            exact
            layout={DefaultLayout}
            path="/users/invites/new"
            title="Invitar usuario"
            user={user}
          />
          <Route
            component={List}
            exact
            editable={isAuthorized('acc:edit')}
            fields={accountFields.list}
            layout={DefaultLayout}
            path="/accounts"
            queryData={QueryAccounts}
            searchKeys={accountFields.search}
            title="Cuentas"
            buttons={[{ label: 'Crear cuenta/usuario', path: 'new' }]}
          />
          <Route
            component={CreateUser}
            exact
            layout={DefaultLayout}
            path="/accounts/new"
            title="Crear cuenta y ususario"
            user={user}
          />
          <Route
            buttons={accountMigrationFields.buttons}
            component={List}
            exact
            fields={accountMigrationFields.list}
            layout={DefaultLayout}
            path="/accounts/migrations"
            queryData={QueryAccountMigrations}
            searchKeys={accountMigrationFields.search}
            title="Migraciones"
          />
          <Route
            component={AccountMigrationCreate}
            layout={DefaultLayout}
            path="/accounts/migrations/new"
            queryData={QueryAccountMigration}
            title="Nueva Migracion"
          />
          <Route
            component={Show}
            fields={accountMigrationFields.show}
            layout={DefaultLayout}
            path="/accounts/migrations/:id"
            queryData={QueryAccountMigration}
            title="Migracion"
          />
          <Route
            component={AccountEdit}
            layout={DefaultLayout}
            path="/accounts/:id/edit"
            queryData={QueryAccount}
            title="Modificar Cuenta"
          />
          <Route
            enableTask="Account"
            commentsEnable
            editable
            component={Show}
            fields={accountFields.show}
            layout={DefaultLayout}
            path="/accounts/:id"
            queryData={QueryAccount}
            title={(data) => {
              return `Detalle de cuenta - ${get(data, 'companies[0].legalName')} -
                ${get(data, 'companies[0].taxId')}`;
            }}
          />
          <Route
            component={List}
            editable={true}
            exact
            fields={subscriptionFields.list}
            layout={DefaultLayout}
            path="/subscriptions"
            queryData={QuerySubscriptions}
            searchKeys={subscriptionFields.search}
            title="Suscripciones"
          />
          <Route
            enableTask="Subscription"
            component={Show}
            commentsEnable
            editable
            exact
            fields={subscriptionFields.show}
            layout={DefaultLayout}
            path="/subscriptions/:id"
            queryData={QuerySubscription}
            title="Ver suscripción"
          />
          <Route
            component={SubscriptionEdit}
            layout={DefaultLayout}
            path="/subscriptions/:id/edit"
            queryData={QuerySubscription}
            title="Modificar suscripción"
            user={user}
          />
          <Route
            editable
            exact
            component={Show}
            fields={userFields.show}
            layout={DefaultLayout}
            path="/users/:id"
            queryData={QueryUser}
            title="Ver usuario"
          />
          <Route
            component={UserEdit}
            layout={DefaultLayout}
            path="/users/:id/edit"
            queryData={QueryUser}
            title="Modificar usuario"
            user={user}
          />
        </Switch>
      </ApolloProvider>
    </BrowserRouter>
  );
}
