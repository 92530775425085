import React, { useState } from 'react';
import { FormCheckbox } from 'shards-react';

export default function IndeterminateCheckbox({
  value,
  className,
  label,
  onChange,
  ...commonProps
}) {
  const { type, ...props } = commonProps;
  const [isChecked, setIsChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);

  const onInputChange = (event) => {
    if (indeterminate) {
      setIndeterminate(false);
      event.target.indeterminate = false;
      setIsChecked(false);
    } else if (!isChecked && !indeterminate) {
      setIsChecked(true);
    } else {
      setIsChecked(true);
      setIndeterminate(true);
      event.target.indeterminate = true;
      event.target.value = 'indeterminate';
    }
    onChange(event);
  };

  return (
    <FormCheckbox
      {...props}
      inline
      type="checkbox"
      checked={isChecked}
      className={className}
      onChange={onInputChange}
    >
      {label}
    </FormCheckbox>
  );
}
