import React from 'react';
import { FormGroup, FormSelect } from 'shards-react';
import { SEGMENTS } from '../../data/fields/mappings/segmentsMap';

export const SegmentSelect = (props) => {
  return (
    <FormGroup>
      <label htmlFor="segment">Segmento</label>
      <FormSelect id="segment" {...props}>
        <option value="">-- Seleccione un segmento --</option>
        {SEGMENTS.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </FormSelect>
    </FormGroup>
  );
};
