export const BUSINESS_CATEGORIES = [
  { value: 'COMERCIO', label: 'Comercio Minorista/Mayorista' },
  { value: 'CONSTRUCCION', label: 'Construcción' },
  { value: 'INDUSTRIA_MANUFACTURA', label: 'Industria y Manufactura' },
  { value: 'SERVICIOS_PROFESIONALES', label: 'Servicios Profesionales' },
  { value: 'TECNOLOGIA', label: 'Tecnología y Software' },
  { value: 'TRANSPORTE', label: 'Transporte y Logística' },
  { value: 'GASTRONOMIA', label: 'Gastronomía y Restaurantes' },
  { value: 'AGRICULTURA', label: 'Agricultura y Ganadería' },
  { value: 'SALUD', label: 'Salud y Servicios Médicos' },
  { value: 'EDUCACION', label: 'Educación y Formación' },
  { value: 'FINANZAS', label: 'Servicios Financieros' },
  { value: 'TURISMO', label: 'Turismo y Hotelería' },
  { value: 'INMOBILIARIO', label: 'Servicios Inmobiliarios' },
  { value: 'ENERGIA', label: 'Energía y Minería' },
  { value: 'OTROS_SERVICIOS', label: 'Otros Servicios' }
];
