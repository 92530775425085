import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, FormGroup, FormInput, Button, FormSelect } from 'shards-react';
import QueryCustomFields from '../../queries/QueryCustomFields';
import CustomField from '../fields/edit/CustomField';
import { Role } from '../../roles';
import { useRole } from '../../contexts/AuthContext';
import { PartnerSelect } from '../FormControls/PartnerSelect';
import { PhoneInput } from '../FormControls/PhoneInput';
import { SalesmanSelect } from '../FormControls/SalesmanSelect';
import { Can } from '../../permissions/helpers';
import { FiscalConditionSelect } from '../FormControls/FiscalConditionsSelect';
import { BusinessCategorySelect } from '../FormControls/BusinessCategory';
import { SegmentSelect } from '../FormControls/SegmentSelect';
import { ProvinceSelect } from '../FormControls/ProvinceSelect';
import { mapGraphQLErrors } from '../../utils/mapGraphQLErrors';
import { query as QueryAccounts } from '../../queries/QueryAccounts';
import { useApolloClient } from '@apollo/client';
import { Link } from 'react-router-dom';

const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
const cuitPattern = '^(20|23|24|27|30|33|34)\\d{9}';

const InviteForm = (props) => {
  const client = useApolloClient();
  const { user } = props;
  const role = useRole();

  const [state, setState] = useState({
    email: '',
    phone: '',
    phoneCountryCode: '+54',
    firstName: '',
    lastName: '',
    extraFields: {
      cbu: '',
      cuit: '',
      companyName: '',
      fiscalCondition: '',
      businessCategory: '',
      segment: '',
      fiscalAddress: '',
      zipCode: '',
      province: ''
    },
    country: 'argentina', //props.user.partner.countries[0].value,
    product: props.user.partner.products[0].value,
    leadNode: props.user.partner.nodes[0].value,
    salesOwnerId: props.user.id,
    isSubmitting: false,
    partnerId: props.user.partnerId,
    errorMessage: '',
    fieldErrors: {},
    accountId: null
  });

  useEffect(() => {
    const partnerId = role === Role.PartnerAdmin ? user.partnerId : user.contactCenterId;

    setState((prevState) => ({ ...prevState, partnerId }));
  }, [role, user]);

  const updateExtraField = (fieldName, value) => {
    setState((prevState) => ({
      ...prevState,
      extraFields: { ...prevState.extraFields, [fieldName]: value }
    }));
  };

  const extraFieldValue = (fieldName) => {
    const extraFields = state.extraFields || {};

    return extraFields[fieldName];
  };

  const fetchAccountDetails = async (filter) => {
    try {
      const result = await client.query({
        query: QueryAccounts,
        variables: { filter }
      });
      const accounts = result.data.accounts;
      if (accounts.length > 0) {
        console.log('Cuentas encontradas:', accounts);
        return accounts[0].id;
      }
      console.log('No se encontraron cuentas con el filtro proporcionado.');
      return null;
    } catch (error) {
      console.error('Error al obtener los detalles de la cuenta:', error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const fields = Object.keys(state.extraFields || {}).reduce((result, key) => {
      result.push({ name: key, value: state.extraFields[key] });
      return result;
    }, []);
    const variables = {
      email: state.email,
      firstName: state.firstName,
      lastName: state.lastName,
      phone: state.phone,
      country: state.country,
      product: state.product,
      leadNode: state.leadNode,
      salesOwnerId: state.salesOwnerId,
      extraFields: fields,
      partnerId: state.partnerId
    };

    setState((prevState) => ({
      ...prevState,
      isSubmitting: true,
      errorMessage: '',
      fieldErrors: {}
    }));

    props.mutate({ variables }).then(
      (_) => {
        props.onSuccess();
      },
      async (error) => {
        const { fieldErrors, generalError } = mapGraphQLErrors(error);
        props.onError(error);
        let accountId = null;

        if (generalError === 'El CUIT ya ha sido utilizado') {
          accountId = await fetchAccountDetails({ taxId: state.extraFields.cuit });
        } else if (
          [
            'El email ya ha sido utilizado',
            'Ya existe una cuenta registrada con este correo electronico'
          ].includes(generalError)
        ) {
          accountId = await fetchAccountDetails({ userEmail: state.email });
        }

        setState((prevState) => ({
          ...prevState,
          isSubmitting: false,
          errorMessage: generalError,
          fieldErrors,
          accountId // Almacena el ID de la cuenta
        }));
      }
    );
  };

  return (
    <Container fluid>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <label htmlFor="product">Producto</label>
          <FormSelect
            id="product"
            invalid={state.fieldErrors && state.fieldErrors.product}
            onChange={(event) => {
              const product = event.target.value;
              setState((prevState) => ({ ...prevState, product }));
            }}
          >
            {user.partner.products.map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </FormSelect>
        </FormGroup>
        <Can action="inv:create:assign-partner">
          <PartnerSelect
            product={state.product}
            defaultValue={state.partnerId}
            country={state.country}
            onChange={(partnerId) => {
              setState((prevState) => ({ ...prevState, partnerId }));
            }}
            required
          />
        </Can>
        <Can action="inv:create:assign-salesman">
          <SalesmanSelect
            user={user}
            onChange={(salesOwnerId) => {
              setState((prevState) => ({ ...prevState, salesOwnerId }));
            }}
          />
        </Can>
        <h4>Datos de la empresa</h4>
        <FormGroup>
          <label htmlFor="company-name">Razón social</label>
          <FormInput
            id="company-name"
            value={extraFieldValue('companyName')}
            onChange={(event) => {
              updateExtraField('companyName', event.target.value);
            }}
            type="text"
          />
        </FormGroup>
        <Row form>
          <Col sm="12" lg="6">
            <FormGroup>
              <label htmlFor="cuit">CUIT</label>
              <FormInput
                id="cuit"
                invalid={state.fieldErrors && state.fieldErrors.cuit}
                value={extraFieldValue('cuit')}
                onChange={(event) => {
                  updateExtraField('cuit', event.target.value);
                }}
                pattern={cuitPattern}
                type="text"
                required
              />
            </FormGroup>
          </Col>
          <Col sm="12" lg="6">
            <FiscalConditionSelect
              value={extraFieldValue('fiscalCondition')}
              onChange={(event) => {
                updateExtraField('fiscalCondition', event.target.value);
              }}
            />
          </Col>
        </Row>
        <Row form>
          <Col sm="12" lg="6">
            <BusinessCategorySelect
              value={extraFieldValue('businessCategory')}
              onChange={(event) => {
                updateExtraField('businessCategory', event.target.value);
              }}
            />
          </Col>
          <Col sm="12" lg="6">
            <SegmentSelect
              value={extraFieldValue('segment')}
              onChange={(event) => {
                updateExtraField('segment', event.target.value);
              }}
            />
          </Col>
        </Row>
        <FormGroup>
          <label htmlFor="fiscal-address">Dirección fiscal</label>
          <FormInput
            id="fiscal-address"
            onChange={(event) => {
              updateExtraField('fiscalAddress', event.target.value);
            }}
            type="text"
          />
        </FormGroup>

        <Row form>
          <Col sm="12" lg="6">
            <FormGroup>
              <label htmlFor="zip_code">Código postal</label>
              <FormInput
                id="zip_code"
                onChange={(event) => {
                  updateExtraField('zipCode', event.target.value);
                }}
                type="text"
              />
            </FormGroup>
          </Col>
          <Col sm="12" lg="6">
            <ProvinceSelect
              value={extraFieldValue('province')}
              onChange={(event) => {
                updateExtraField('province', event.target.value);
              }}
            />
          </Col>
        </Row>
        <FormGroup>
          <label htmlFor="cbu">Medio de pago (Nro de CBU)</label>
          <FormInput
            id="cbu"
            invalid={state.fieldErrors && state.fieldErrors.cbu}
            value={extraFieldValue('cbu')}
            onChange={(event) => {
              updateExtraField('cbu', event.target.value);
            }}
            type="text"
            required
          />
        </FormGroup>
        <h4>Datos del usuario administrador</h4>
        <FormGroup>
          <label htmlFor="firstName">Nombre</label>
          <FormInput
            id="firstName"
            invalid={state.fieldErrors && state.fieldErrors.first_name}
            onChange={(event) => {
              const firstName = event.target.value;
              setState((prevState) => ({ ...prevState, firstName }));
            }}
            type="text"
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="lastName">Apellido</label>
          <FormInput
            id="lastName"
            invalid={state.fieldErrors && state.fieldErrors.last_name}
            onChange={(event) => {
              const lastName = event.target.value;
              setState((prevState) => ({ ...prevState, lastName }));
            }}
            type="text"
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="email">Email del usuario</label>
          <FormInput
            id="email"
            invalid={state.fieldErrors && state.fieldErrors.email}
            onChange={(event) => {
              const email = event.target.value;
              setState((prevState) => ({ ...prevState, email }));
              if (state.isSubmitting) {
                setState((prevState) => ({ ...prevState, isSubmitting: false }));
              }
            }}
            pattern={emailRegex.source}
            type="email"
            required
          />
        </FormGroup>
        <PhoneInput
          phoneCountryCode={state.phoneCountryCode}
          countries={user.partner.countries}
          onChange={(phone) => {
            setState((prevState) => ({ ...prevState, phone }));
          }}
        />
        <QueryCustomFields partnerId={state.partnerId}>
          {(data) =>
            data.map((field) => (
              <FormGroup key={field.id}>
                <CustomField
                  key={field.id}
                  field={field}
                  value={extraFieldValue(field.name)}
                  onChange={(event) => updateExtraField(field.name, event.target.value)}
                  required
                />
              </FormGroup>
            ))
          }
        </QueryCustomFields>
        <Row className="align-items-center">
          <Col lg="auto">
            <Button type="submit" disabled={state.isSubmitting}>
              {props.submitLabel || 'Invitar usuario'}
            </Button>
          </Col>
          <Col>
            {state.errorMessage && (
              <div style={{ color: 'red' }}>
                {state.errorMessage}
                {state.accountId && (
                  <Link to={`/accounts/${state.accountId}`} style={{ marginLeft: '10px' }}>
                    Ver cuenta
                  </Link>
                )}
              </div>
            )}
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default InviteForm;
