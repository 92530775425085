import ListTextField from '../../components/fields/list/TextField';
import ShowTextField from '../../components/fields/show/TextField';
import ShowDateField from '../../components/fields/show/DateField';
import AttributeField from '../../components/fields/list/AttributeField';
import ListDateField from '../../components/fields/list/DateField';
import ListSubscriptionStateField from '../../components/fields/list/SubscriptionStateField';
import OwnerUserNameField from '../../components/fields/list/OwnerUserNameField';
import MappedField from '../../components/fields/show/MappedField';

const accountFields = {
  list: {
    createdAt: { label: 'Fecha de creación', content: ListDateField },
    taxId: { label: 'CUIT', content: ListTextField },
    name: { label: 'Razón social', content: ListTextField },
    users: { label: 'Usuario principal', content: OwnerUserNameField }
  },
  show: {
    companyTitle: { label: 'Datos de la empresa', type: 'title' },
    'companies[0].legalName': { label: 'Razón social', content: ShowTextField },
    'companies[0].taxId': { label: 'CUIT', content: ShowTextField },
    'companies[0].metadata.tax_condition': {
      label: 'Condición fiscal',
      content: MappedField('FISCAL_CONDITIONS')
    },
    'companies[0].metadata.industry': {
      label: 'Rubro',
      content: MappedField('BUSINESS_CATEGORIES')
    },
    'companies[0].metadata.segment': { label: 'Segmento', content: MappedField('SEGMENTS') },
    'companies[0].address': {
      label: 'Dirección fiscal',
      type: 'object',
      content: {
        street: { label: 'Calle', content: ShowTextField },
        postal_code: { label: 'Código postal', content: ShowTextField },
        country: { label: 'País', content: ShowTextField },
        province: { label: 'Provincia', content: MappedField('PROVINCES') }
      }
    },
    'companies[0].paymentMethod.metadata.cbu': {
      label: 'Medio de pago CBU',
      content: ShowTextField
    },
    userTitle: { label: 'Datos del usuario principal', type: 'title' },
    'users[0].firstName': { label: 'Nombre', content: ShowTextField },
    'users[0].lastName': { label: 'Apellido', content: ShowTextField },
    'users[0].email': { label: 'Email', content: ShowTextField },
    'users[0].phoneNumber': { label: 'Teléfono', content: ShowTextField },
    'users[0].accepted_at': { label: 'Fecha de aceptación', content: ShowDateField },
    'users[0].status': {
      label: 'Estado de mail de creación de contraseña',
      content: ShowTextField
    },
    subscriptionsTitle: { label: 'Suscripciones a productos', type: 'title' },
    subscriptions: {
      label: 'Subscripciones',
      type: 'nested',
      path: '/subscriptions',
      links: true,
      content: {
        salesOwner: { label: 'Implementador', content: ShowTextField },
        product: { label: 'Producto', content: AttributeField('name') },
        state: { label: 'Estado', content: ListSubscriptionStateField }
      }
    },
    accountTitle: { label: 'Datos de la cuenta', type: 'title' },
    id: { label: 'ID', content: ShowTextField },
    partner: { label: 'Partner', content: ShowTextField },
    createdAt: { label: 'Fecha de creación', content: ShowDateField },
    taskAndCommentsTitle: { label: 'Tareas y comentarios', type: 'title' },
    tasks: {
      label: 'Tareas',
      type: 'nested',
      links: false,
      content: {
        priority: { label: 'Prioridad', content: AttributeField('name') },
        state: { label: 'Estado', content: AttributeField('name') },
        creator: { label: 'Creador', content: AttributeField('email') },
        assignedTo: { label: 'Asignado', content: AttributeField('email') },
        dueAt: { label: 'Vencimiento', content: ListDateField }
      }
    },
    comments: {
      label: 'Comentarios',
      type: 'nested',
      content: {
        writerEmail: { label: 'Escritor', content: ShowTextField },
        body: { label: 'Comentario', content: ShowTextField },
        productName: { label: 'Producto', content: ShowTextField },
        createdAt: { label: 'Fecha', content: ShowDateField }
      }
    }
  },
  search: [
    { key: 'partnerId', label: 'Partner', type: 'partner', permission: 'acc:search:partner' },
    { key: 'accountName', label: 'Razón social' },
    { key: 'taxId', label: 'CUIT' },
    { key: 'userEmail', label: 'email' }
  ]
};

export default accountFields;
