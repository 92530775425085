import React, { useMemo, useState } from 'react';
import { Container, Row, Card, CardBody } from 'shards-react';
import { isEmpty, pickBy } from 'lodash';
import PageTitle from '../layouts/PageTitle';
import ShowBody from './ShowBody';
import TaskButton from './TaskButton';
import EditButton from './EditButton';
import { useAuthorize } from '../permissions/helpers';
import CommentsModals from './CommentsModal';
import CommentsButton from './CommentsButton';
import MutationAddComment from '../mutations/MutationAddComment';
import callIfFunction from '../utils/callIfFunction';

export default function(props) {
  const { fields } = props;
  const isAuthorized = useAuthorize();
  const authorizedFiels = useMemo(() => {
    return pickBy(fields, (field) => isEmpty(field.permission) || isAuthorized(field.permission));
  }, [isAuthorized, fields]);
  const [openCommentBox, setOpenCommentBox] = useState(false);

  return (
    <Container fluid>
      <props.queryData id={props.match.params.id}>
        {(data, refetch) => (
          <>
            <Row className="page-header py-4 position-relative" style={{ gap: '1rem' }} noGutters>
              <PageTitle className="text-sm-left" title={callIfFunction(props.title, data)} />
              {props.editable && <EditButton id={props.match.params.id} />}
              {props.enableTask && (
                <TaskButton id={props.match.params.id} type={props.enableTask} />
              )}
              {props.commentsEnable && <CommentsButton setOpenCommentBox={setOpenCommentBox} />}
            </Row>
            <Card>
              <CardBody>
                <ShowBody data={data} fields={authorizedFiels} />
              </CardBody>
              {props.commentsEnable && (
                <MutationAddComment>
                  {(mutate) => (
                    <CommentsModals
                      openCommentBox={openCommentBox}
                      setOpenCommentBox={setOpenCommentBox}
                      queryData={data}
                      refetch={refetch}
                      mutate={mutate}
                    />
                  )}
                </MutationAddComment>
              )}
            </Card>
          </>
        )}
      </props.queryData>
    </Container>
  );
}
