import React from 'react';
import { Button, Col } from 'shards-react';

const CommentsButton = ({ setOpenCommentBox, className }) => {
  return (
    <Col xs="auto" className={className}>
      <Button onClick={() => setOpenCommentBox(true)}>Agregar Comentario</Button>
    </Col>
  );
};

export default CommentsButton;
