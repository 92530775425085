import React from 'react';
import styled from 'styled-components';
import { FormInput, FormCheckbox, FormSelect } from 'shards-react';
import QueryPartners from '../../queries/QueryPartners';
import QueryAppUsers from '../../queries/QueryAppUsers';
import QueryInvitationStates from '../../queries/QueryInvitationStates';
import IndeterminateCheckbox from './IndeterminateCheckbox';
import QueryCountries from '../../queries/QueryCountries';
import SearchSelect from './SearchSelect';
import QueryProducts from '../../queries/QueryProducts';

const SearchInput = styled(FormInput)`
  width: auto;
  min-width: 200px;
`;

const SearchUser = styled(FormSelect)`
  width: 200px;
`;

export default function SearchControl({ type, label, onChange, value, name, content }) {
  const commonProps = { name, onChange };
  switch (type) {
    case 'select':
      return (
        <SearchSelect
          {...commonProps}
          value={value}
          options={content}
          label={label}
          type="select"
          className="mb-0"
        />
      );
    case 'indeterminate-checkbox':
      return (
        <IndeterminateCheckbox {...commonProps} value={value} className="mb-0" label={label} />
      );
    case 'checkbox':
      return (
        <FormCheckbox
          {...commonProps}
          type="checkbox"
          checked={value || false}
          className="mb-0"
          inline={true}
        >
          {label}
        </FormCheckbox>
      );
    case 'partner':
      return (
        <QueryPartners>
          {(partners) => (
            <SearchSelect
              {...commonProps}
              value={value}
              options={partners}
              label={label}
              type="select"
              labelKey="name"
              valueKey="id"
              className="mb-0"
            />
          )}
        </QueryPartners>
      );
    case 'users':
      return (
        <QueryAppUsers>
          {(users) => (
            <SearchUser {...commonProps} type="select">
              <option selected={!value} value="">
                -- implementador --
              </option>
              {users.map((u) => (
                <option key={u.id} value={u.id}>
                  {u.email}
                </option>
              ))}
            </SearchUser>
          )}
        </QueryAppUsers>
      );
    case 'invitationStates':
      return (
        <QueryInvitationStates>
          {(states) => (
            <SearchSelect {...commonProps} type="select">
              <option selected={!value} value="">
                -- Filtrar por estado --
              </option>
              {states.map((s) => (
                <option key={s.id} value={s.id}>
                  {s.label}
                </option>
              ))}
            </SearchSelect>
          )}
        </QueryInvitationStates>
      );
    case 'country':
      return (
        <QueryCountries>
          {(countries) => (
            <SearchSelect
              {...commonProps}
              value={value}
              options={countries}
              label={label}
              type="select"
              className="mb-0"
            />
          )}
        </QueryCountries>
      );
    case 'product':
      return (
        <QueryProducts>
          {(products) => (
            <SearchSelect
              {...commonProps}
              value={value}
              options={products}
              label={label}
              type="select"
              className="mb-0"
            />
          )}
        </QueryProducts>
      );
    default:
      break;
  }
  return <SearchInput {...commonProps} value={value || ''} placeholder={`Buscar por ${label}`} />;
}
